.lineClampin {
    position: relative;
    overflow: hidden;
    height: 9.1rem; /* exactly three lines */
}
.lineClampin:after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.2em;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(242, 242, 242, 1) 50%
    );
}
