$breakpoint: (75: 0.75,
    80: 0.80,
    85: 0.85,
    90: 0.90,
    95: 0.95,
    100: 1,
    105: 1.05,
    110: 1.1,
    115: 1.15,
    120: 1.2,
    125: 1.25,
    150: 1.5,
    175: 1.75,
    200: 2,
    250: 2.5,
    300: 3,
    350: 3.5,
    400: 4,
    450: 4.5,
    500: 5);

@each $name,
$value in $breakpoint {
    .f-#{$name} {
        font-size: #{$value}rem;
    }

    .l-#{$name} {
        line-height: #{$value}rem;
    }

    .fl-#{$name} {
        font-size: #{$value}rem;
        line-height: #{$value}rem;
    }

    @include media-breakpoint-up(sm) {
        .f-sm-#{$name} {
            font-size: #{$value}rem;
        }

        .l-sm-#{$name} {
            line-height: #{$value}rem;
        }

        .fl-sm-#{$name} {
            font-size: #{$value}rem;
            line-height: #{$value}rem;
        }
    }

    @include media-breakpoint-up(md) {
        .f-md-#{$name} {
            font-size: #{$value}rem;
        }

        .l-md-#{$name} {
            line-height: #{$value}rem;
        }

        .fl-md-#{$name} {
            font-size: #{$value}rem;
            line-height: #{$value}rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .f-lg-#{$name} {
            font-size: #{$value}rem;
        }

        .l-lg-#{$name} {
            line-height: #{$value}rem;
        }

        .fl-lg-#{$name} {
            font-size: #{$value}rem;
            line-height: #{$value}rem;
        }
    }

    @include media-breakpoint-up(xl) {
        .f-xl-#{$name} {
            font-size: #{$value}rem;
        }

        .l-xl-#{$name} {
            line-height: #{$value}rem;
        }

        .fl-xl-#{$name} {
            font-size: #{$value}rem;
            line-height: #{$value}rem;
        }
    }
}