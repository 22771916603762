html,
body,
#root,
.App {
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    .AppContent {
        flex: 1 0 auto;
    }
    .AppFooter {
        flex-shrink: 0;
    }
}
