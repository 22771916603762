/* VARIABLES */
@import "global/b4-overwrite.scss";

/* ============================================= 
------------------Plugins-----------------------
===============================================*/
//---------------bootstrap4----------------------
@import "plugins/bootstrap4/bootstrap.scss";
// @import "mdb/mdb-pro.scss";

//---------------ekko-lightbox-------------------
@import "plugins/ekko-lightbox/ekko-lightbox";

//-------------font awesome----------------------
$fa-font-path: "../fonts";
@import "plugins/fontawesome/fontawesome.scss";
@import "plugins/fontawesome/solid.scss";
@import "plugins/fontawesome/duotone.scss";
@import "plugins/fontawesome/light.scss";
@import "plugins/fontawesome/brands.scss";
@import "plugins/fontawesome/regular.scss";

/* own plugins */
@import "global/ownPlugins";

// global styles
@import "global/misc";

@import "global/theme";
