.homePage__header a {
    &:hover {
        color: #fff;
        text-decoration: none;
    }
}

table {
    td {
        font-size: 0.9rem;
    }
}
